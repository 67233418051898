body .change-pass-form{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
    background-color: var(--dark-black-color);
    color: white;
}

.change-pass-form .logo{
    width: 137px;
    height: 42px;
    position: absolute;
    top: 22px;
    left: 78px;
}

.change-pass-form .bg{
    height: 80vh;
}

.change-pass-form .bg img{
    height: 100%;
}

@media (max-width: 768px){
    .change-pass-form .bg{
        display: none;
    }
}

.change-pass-form .form-container{
    flex: auto;
    margin: 0 20px;
    min-width: 420px;
}

@media(max-width: 576px){
    .change-pass-form .form-container{
        min-width: 280px;
        margin: 0;
    }
}

.change-pass-form .form-container form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    margin: 0 auto;
    max-width: 555px;
    width: 80%;
}

@media (max-width: 768px){
    .change-pass-form .form-container form{
        align-items: center;
    }
}

.change-pass-form .form-container form .password{
    width: 80%;
}

footer{
    position: absolute;
    bottom: 10px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0);
}

.change-pass-form .rounded-box {
    width: 100%;
    background: transparent;
    border: 1px solid gray;
    color: white;
}