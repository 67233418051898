.manage-branch-popup {
    width: 98% !important;
    max-width: 98% !important;
    min-width: 98% !important
}

.manage-branch-setting-popup {
    width: 70% !important;
    max-width: 70% !important;
    min-width: 70% !important
}

@media (max-width: 770px) {    
    .manage-branch-setting-popup {
        width: 98% !important;
        max-width: 98% !important;
        min-width: 98% !important
    }
}
