.attend-label-row {
    padding: 5px 0px;
}

.attend-label-child:last-child {
    flex: 1;
    width: 100%;
    text-align: right;
}

.attend-session-left {
    color: #807F7F;
}