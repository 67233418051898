.branch-stats-container {
    width: 32%;
    margin-top: 20px;
}

.branch-stats-name {
    font: normal normal bold 14px/16px Public Sans;
    letter-spacing: 0;
    color: #191919;
    text-transform: uppercase;
    opacity: 1
}

.branch-stats-counter-container {
    display: flex;
    flex-direction: column;
}

.branch-stats-counter-parent {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000014;
    border-radius: 3;
    opacity: 1;
    padding: 15px 0;
}

.branch-stats-counter-ar {
    display: flex;
    flex-direction: column;
    border-right: solid 3px black;
    gap: 5px;
    padding-right: 15px;
}

.branch-stats-counter-en {
    display: flex;
    flex-direction: column;
    border-left: solid 3px black;
    gap: 5px;
    padding-left: 15px;
}

.branch-stats-title {
    font: normal normal bold 10px/11px Public Sans;
    letter-spacing: 0px;
    color: #807F7F;
    opacity: 1;
}

.branch-stats-counter {
    font: normal normal bold 22px/26px Public Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.branch-stats-overview {
    font: normal normal normal 12px/14px Public Sans;
    letter-spacing: 0px;
    color: #0F0F0F;
    opacity: 1;
}