.data-header {
    color: #807F7F;
}

.right-text {
    text-align: right;
}

.no-border {
    border: none !important;
}