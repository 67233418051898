.chart-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px
}

.chart-child {
    height: auto;
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000014;
    border-radius: 3px;
    opacity: 1;
    padding: 20px;
    padding-top: 27px;
}

@media (max-width: 1060px) {
    .chart-child {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .chart-parent {
        display: flex;
        flex-direction: column;
    }
    .chart-child {
        width: 100%;
        margin-bottom: 10px;
    }
}