.extra-member-row {
    margin-bottom: 15px;
}

.add-extra-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.extra-total {
    height: 48px;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #191919;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox {
    border-radius: 50% !important;
}