i.close-btn {
    position: absolute;
    right: 10px;
    top: -5px;
    color: #E03F3F;
}

i.close-btn:hover {
    cursor: pointer;
}

.close-row {
    position: relative;
}