.shared-data-line{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.shared-data-line .shared-data-line-title{
    color: var(--label-color);
    min-width: 180px;
    max-width: 180px;
}

.shared-data-line .shared-data-line-data{
    color: var(--primary-black-color);
    flex: auto;
    display: flex;
}

.shared-data-line .shared-data-line-data{
    justify-content: space-between;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.shared-data-line .shared-data-line-data span{
    margin-inline-end: 10px;
}

.shared-data-line .shared-data-line-control{
    color: var(--main-color);
    margin-inline-start: auto;
}

.shared-data-line .shared-data-line-control:disabled{
    opacity: 0.7;
    cursor: auto;
}