.attend-row {
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.attend-row div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.attend-header {
    color: #807F7F;
}