.page-content .membership-cards-container{
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 15px;
    padding: 20px;
}

@media(max-width: 595px){
    .page-content .membership-cards-container{
        justify-content: center;
    }
}

.membership-cards-container .membership-card{
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    min-width: 270px;
    max-height: fit-content;
    background: var(--table-header-color);
    border: 1px solid var(--inactive-white-color);
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.membership-card .offer-badge.en{
    position: absolute;
    top: 0;
    left: 0;
}

.membership-card .offer-badge.ar{
    position: absolute;
    top: 0;
    right: 0;
}

.membership-card .dots{
    color: var(--label-color);
    font-weight: bold;
    font-size: 1.3rem;
    padding: 5px;
    position: absolute;
    top: 15px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
}

.membership-card .dots.en{
    right: 15px;
}

.membership-card .dots.ar{
    left: 15px;
}

.membership-card .duration{
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--label-color);
}

.membership-card .main-title{
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 15px;
    min-height: 75px;
}

.membership-card .main-title .price , .old-price{
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--primary-black-color);
}

.old-price{
    font-size: 0.7rem !important;
    text-transform: uppercase;
    color: var(--label-color) !important;
    text-decoration: line-through;
}

.membership-card .duration-left{
    color: var(--main-color);
    font-size: 0.75rem;
}

.membership-card .options{
    text-align: start;
}

.membership-card .options li{
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

.membership-card .options li::before{
    content: "\2022"; 
    color: var(--main-color);
    font-size: 23px;
    font-weight: bold;
    display: inline-block;
    width: 15px;
}

.membership-card .btn{
    background: transparent;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    margin-top: 20px !important;
    padding: 10px 30px;
    margin: 0 auto;
}

.membership-card .btn:disabled{
    opacity: 0.5;
    cursor: auto;
}

.subscription-title {
    font-size: 1rem;
    color: var(--primary-black-color);
}

.membership-actions {
    position: absolute;
    top: 40px;
    right: 5px;
    width: 176px;
    min-height: 90px;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-shadow: 0px 0px 6px #0000001F;
}

.small-btn {
    justify-content: start;
    padding-left: 20px;
}

.menu-action-hover:hover {
    background-color: var(--main-color);
    color: white;
}