.search-box{
    flex: auto;
    position: relative;
}

.search-box .i-button {
    position: absolute;
    top: 17px;/* we need this updated depend on rounded-box margin-top */
    right: 5px;
    padding: 11px;
    border-radius: 50%;
    color: gray;
}

.search-box .i-button:hover {
    color: white;
    background: #f78080;
    cursor: pointer;
}

.search-box .rounded-box{
    padding-inline-start: 25px;
    width: 100%;
}

.close-button {
    position: absolute;
    top: 17px;/* we need this updated depend on rounded-box margin-top */
    right: 50px;
    padding: 11px;
    border-radius: 50%;
    color: gray;
}

.close-button:hover {
    cursor: pointer;
}

.search-box span {
    position: absolute;
    right: 45px;
    top: 24px;
    color: gray;
}

.small-search {
    padding: 15px !important;
    padding-left: 15px !important;
    padding-right: 85px !important
}

.clear-left-padding {
    padding-left: 15px !important;
}

.mr-10 {
    margin-right: 10px;
}

.embeded-dropdown .i-button,
.embeded-dropdown .close-button {
    top: 5px !important
}

.embeded-dropdown span {
    top: 12px !important
}