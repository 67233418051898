.counters {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.counters .counter-child {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000014;
    opacity: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.counters .counter-child span:nth-child(1) {
    font-size: 12px;
    color: #807F7F;
}

.counters .counter-child span:nth-child(2) {
    font-size: 25px;
    font-weight: bold;
}

.counters .counter-child i {
    font-size: 25px;
    text-align: right;
}

.red-top-border {
    border-top: 3px solid #E03F3F;
}

.green-top-border {
    border-top: 3px solid #6DBE43;
}

.orange-top-border {
    border-top: 3px solid #F26F10;
}

.color-red {
    color: #E03F3F;
}

.color-green {
    color: #6DBE43;
}

.color-orange {
    color: #F26F10;
}

.red-alert {
    color: #e0423f !important;
    font-size: 10px !important;
    position: absolute;
    top: 40px;
    left: 20px;
}