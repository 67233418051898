.banner-ads-modal {
    width: 472px !important;
}

.banner-ads-section {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-ads-section img {
    width: 432px;
    height: 432px;
}

.banner-ads-section p {
    margin-top: 25px;
    text-align: center;
}

.banner-ads-actions {
    display: flex;
    justify-content: center;
}

.banner-ads-ul {
    display: inline-flex;
    margin-bottom: 15px;
}

.banner-ads-ul .banner-ads-dot,
.banner-ads-ul .banner-ads-dotactive {
    width: 8px;
    height: 8px;
    background: #DDDDDD 0% 0% no-repeat padding-box;
    border-radius: 50%;
    margin: 0 8px;
}

.banner-ads-ul .banner-ads-dotactive {
    background: #E03F3F 0% 0% no-repeat padding-box;
}

.banner-ads-ul .banner-ads-dot:hover,
.banner-ads-ul .banner-ads-dotactive:hover{
    cursor: pointer;
}