.details-page-content{
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 3px #00000014;
}

.details-page-content .table-header {
    padding: 10px 20px;
    background-color: #F8F8F8;
    color: #191919;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
}

.details-page-content .details-page-section{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--white-color);
    width: 704px;
    box-shadow: 0 0 3px var(--light-shadow-color);
    flex-direction: column;
}

.details-page-section .details-img-container{
    position: relative;
}

.details-page-section .details-img{
    width: 254px;
    height: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--table-header-color);
    border-radius: 50%;
    overflow: hidden;
}

.details-page-section .details-img-container .details-cam-btn.en{
    position: absolute;
    top: 74%;
    right: 5%;
}

.details-page-section .details-img-container .details-cam-btn.ar{
    position: absolute;
    top: 74%;
    right: 5%;
}

.details-img-container #file-uploader{
    display: none;
}

.details-page-section .details-gym-info{
    margin-inline-end: auto;
    width: 100%;
}

.details-page-section .controls ,.details-page-section .table-container{
    width: 100%;
}

.details-gym-info .details-info-section{
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-gym-info .details-info-section:not(:last-child){
    border-bottom: 1px solid var(--border-color);
}

.details-gym-info .details-info-section .details-info-title{
    font-weight: bold;
    text-transform: uppercase;
}

.details-gym-info .details-info-section .details-info-data{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-gym-info .details-info-section .details-info-data .info-badge{
    text-align: center;
    padding: 10px;
    background: var(--table-header-color);
    border: 1px solid var(--inactive-white-color);
    border-radius: 10px;
}

@media(max-width: 668) {
    .details-page-section{
        width: 100;
    }
}

.toggle{
    background-color: var(--inactive-white-color);
    width: 30px;
    height: 15px;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
    
.toggle::before{
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--white-color);
    border: 2.5px solid var(--label-color);
    top: 0%;
    left: 0%;
}
    
.toggle:hover{
    cursor: pointer;
    }
    
.toggle.active{
    background-color: var(--main-color);
}
    
.toggle.active::before{
    border-color: var(--main-color);
    left: 50%;
}

.toggle::before{
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--white-color);
    border: 2.5px solid var(--label-color);
    top: 0%;
    left: 0%;
}

.toggle:hover{
    cursor: pointer;
}