.custom-order-form > .contain {
    max-width: 996px !important;
    min-width: 996px !important;
}

/*** make fixed width useful in responsive mode ***/
@media (max-width: 1024px) {
    .custom-order-form {
        width: 100vh !important;
        height: 100vh !important;
        min-width: 1024px;
        max-width: 100%;
    }

    .custom-order-form > .contain {
        max-width: 100% !important;
        min-width: 100% !important;
        padding-right: 15px;
        padding-left: 15px;
    }

    .custom-order-form {
        min-height: 100% !important;
        max-height: 100% !important;
        padding: 0 !important;
    }

    .custom-order-form form {
        overflow-x: scroll;
    }

    .custom-order-form > .contain .header {
        max-width: 996px !important;
        min-width: 996px !important;
    }
}
/*** end of fixed width ***/

.custom-order-form form {
    max-width: 996px !important;
    min-width: 996px !important;
    padding: 0 !important;
    height: 580px;
    display: flex;
    flex-direction: column;
    background-color: #0f0f0f !important;
}

/*** form title styles ***/
.custom-order-form .custom-header {
    height: 50px;
    width: 100%;
    background-color: #0f0f0f;
    display: flex;
    flex-direction: row;
}

.custom-order-form .bar-icons {
    width: calc(996px - 344px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:flex-end;
    color: white;
}

.custom-order-form .bar-icons div {
    padding: 0 35px;
    border-bottom: 3px solid #0f0f0f !important;
}

.custom-order-form .bar-icons div.active {
    border-bottom: 3px solid white !important;
}

.custom-order-form .details-title {
    width: 344px;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    font: normal normal normal 22px/30px Public Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
/*** end of form title styles ***/

/*** container body ***/
.custom-order-form .custom-body {
    width: 100%;
    height: calc(580px - 50px);
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}
/*** end of container body ***/

/*** products section styles ***/
.custom-order-form .products-section {
    width: calc(996px - 344px);
    height: 100%;
    border-bottom-left-radius: 10px;
    border-right: #c3c3c3 1px solid;
    display: flex;
    flex-direction: column;
}

.custom-order-form .products-section .categories {
    width: 100%;
    height: 80px;
    border-bottom: #c3c3c3 1px solid;
    white-space: nowrap;
    padding-top: 40px;
    position: relative;
}

.custom-order-form .products-section .categories .scroll-able {
    overflow-y: hidden;
    overflow-x: hidden;
}

.custom-order-form .products-section .categories #categories-controls {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
}

.custom-order-form .products-section .categories #categories-controls i.fa-arrow-left {
    margin-right: 10px;
}

.custom-order-form .products-section .categories #categories-controls i {
    width: 25px;
    height: 25px;
    background-color: #191919;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-order-form .products-section .categories .category-div {
    padding: 0 20px;
    color: #807F7F;
    width: auto;
    display: table-cell;
}

.custom-order-form .products-section .categories .category-div.active {
    color: #0f0f0f;
    border-bottom: #0f0f0f 2px solid;
}

.custom-order-form .products-section .search {
    width: 100%;
    height: 100px;
    padding: 10px 20px;
}

.custom-order-form .products-section .products {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.custom-order-form .products-section .products-row {
    display: flex;
    flex-direction: row;
    margin: 0 20px;
    padding-bottom: 5px;
    border-bottom: #c3c3c3 1px solid;
    justify-content: space-between;
    margin-bottom: 15px;
}

.custom-order-form .products-section .product-info {
    flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.custom-order-form .products-section .product-price {
    font: normal normal normal 14px/16px Public Sans;
    letter-spacing: 0px;
    color: #E03F3F;
    opacity: 1;
}

.custom-order-form .products-section .product-title {
    font: normal normal medium 16px/19px Public Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.custom-order-form .products-section .available-qnt {
    flex: 0 0 12%;
    margin-right: 10px;
}

.custom-order-form .products-section .available-qnt select {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    width: 100%;
    padding: 5px;
}

.custom-order-form .products-section .plus {
    border: 1px solid #191919;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    background-color: #191919;
}
/*** end of products section styles ***/

/*** order item section styles ***/
.custom-order-form .order-items-section {
    width: 344px;
    height: 100%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-bottom-right-radius: 10px;
    position: relative;
}

.custom-order-form .order-items-section .cart {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.custom-order-form .order-items-section .cart .cart-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 20px 10px 16px;
    border-bottom: #c3c3c3 1px solid;
}

.custom-order-form .order-items-section .cart-row .info {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
}

.custom-order-form .order-items-section .cart-row .info .title {
    font: normal normal normal 14px/16px Public Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.custom-order-form .order-items-section .cart-row .info .price {
    font: normal normal bold 12px/14px Public Sans;
    letter-spacing: 0px;
    color: #E03F3F;
    opacity: 1;
}

.custom-order-form .order-items-section .cart-row .qnt > input {
    border: 1px solid #D3D3D3;
    border-radius: 24px;
    opacity: 1;
    width: 75px;
    padding: 5px;
    text-align: center;
}

.custom-order-form .order-items-section .cart-row .delete {
    border: 1px solid #E03F3F;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E03F3F !important;
}

.custom-order-form .order-items-section .place-order-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #E03F3F 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 10px 0px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
}

.custom-order-form .order-items-section .place-order-btn.disabled {
    background: #dd7474 0% 0% no-repeat padding-box;
    cursor: none;
}
/*** end of order item section styles ***/