body .sign-in-form{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: var(--dark-black-color);
    color: white;
}

.sign-in-form .bg{
    height: 80vh;
}

.sign-in-form .bg img{
    height: 100%;
}

@media (max-width: 1024px){
    .sign-in-form .bg{
        display: none;
    }
}

.sign-in-form .login-form-container{
    flex: auto;
    margin: 0 20px;
    min-width: 420px;
}

@media(max-width: 576px){
    .sign-in-form .login-form-container{
        min-width: 280px;
    }
}

.sign-in-form .login-form-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0 auto;
    max-width: 555px;
    width: 80%;
}

.login-form-container form img{
    width: 212px;
    height: 65px;
    margin-bottom: 26px;
}

.login-form-container form .rounded-box{
    width: 80%;
    background: transparent;
    border: 1px solid gray;
    color: white;
}

.login-form-container form .password{
    width: 80%;
}

.password .toggler-icon i{
    color: var(--label-color);
}

.password .toggler-icon i.show{
    color: var(--white-color);
}

.login-form-container form .checkbox{
    align-self: flex-start;
    margin-bottom: 30px;
}

.login-form-container form .checkbox.en{
    margin-left: 10%;
}

.login-form-container form .checkbox.ar{
    margin-right: 10%;
}

.login-form-container form a{
    text-transform: capitalize;
    color: var(--white-color);
}