.page-relative {
    position: relative;
}

.leftover-parent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #F5F7F8 0% 0% no-repeat padding-box;
    z-index: 99999;
    padding: 15px 20% 25px 20%
}

@media (max-width: 1024px) {
    .leftover-parent {
        padding: 15px 10% 25px 10%
    }
}

.leftover-back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    color: black
}

.leftover-content {
    width: 100%;
    height: 100%;
    margin: auto;
    background: white;
    box-shadow: 0px 0px 6px #0000001F;
    border-radius: 10px
}

.leftover-header {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0 25px;
}

.leftover-member {
    font-weight: bold;
}

.leftover-member span {
    font-weight: normal;
}

.leftover-cost {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.leftover-cost div span {
    color: #191919;
    font-weight: bold;
}

.leftover-cost div {
    flex: 0 0 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 10px 15px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px dashed #DDDDDD;
    border-radius: 30px;
}

.leftover-parent .btn-light:disabled {
    color: #f8f9fa !important;
}