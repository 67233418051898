.page-content-help-center{
    align-items: center;
}

.page-content-help-center .page-section-help-center{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: var(--white-color);
    max-width: 704px;
    border-radius: 10px;
    box-shadow: 0 0 3px var(--light-shadow-color);
    width: 100%;
    margin-top: 0;
}

.page-section-help-center .rounded-box{
    border: 1px solid var(--inactive-white-color);
}

.page-section-help-center .dropdown-menu-help-center{
    border: 1px solid var(--inactive-white-color);
}

.page-section-help-center .dropdown-help-center,
.page-section-help-center input,
.page-section-help-center textarea{
    width: 100%;
    margin: 0;
    resize: none;
}

.page-section-help-center textarea{
    height: 100px;
}

.img-upload-help-center{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.img-upload-help-center:hover {
    cursor: pointer;
}

.img-upload-help-center .dropzone-help-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: var(--light-bg-color);
    border-radius: 5px;
    border: 3px dashed var(--inactive-white-color);
}

.img-upload-help-center .dropzone-help-center.active{
    border: 3px dashed var(--dropzone-active-border-color);
}

.img-upload-help-center .dropzone-help-center i{
    font-size: 1.5rem;
    color: var(--label-color);
}

.img-upload-help-center .dropzone-help-center span{
    text-align: center;
}

.img-upload-help-center .dropzone-help-center .palceholder-help-center{
    font-size: 10px;
    font-weight: bold;
    color: var(--label-color);
    text-transform: uppercase;
    margin-top: 5px;
}

.img-upload-help-center #file-uploader{
    display: none;
}

.send-help-center{
    box-sizing: border-box;
    margin-top: 20px;
    width: 188px;
    height: 48px;
}

.full-width {
    width: 100% !important;
}

.clear-margin {
    margin: 0 !important
}

.attachment-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.red-text {
    color: #E03F3F;
}

.clickable:hover {
    cursor: pointer;
}

img.small-image {
    width: 34px;
    height: 34px;
}

div.actions{
    display: flex;
    flex-direction: row;
    gap: 20px;
}