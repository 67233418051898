#modal-form .contain {
    max-width: 696px;
}
  
#modal-form .rounded-box {
    width: 100%;
    min-width: 152px;
    max-width: 696px;
}
  
#modal-form .confirm {
    display: flex;
    justify-content: flex-end;
}

#modal-form .contain {
  max-width: 696px;
}

#modal-form .rounded-box {
  width: 100%;
  min-width: 152px;
  max-width: 656px;
}

#modal-form .confirm {
  display: flex;
  justify-content: flex-end;
}

#modal-form .form-group {
  position: relative;
}

#modal-form .padding.en {
  padding-right: 75px;
}

#modal-form .padding.ar {
  padding-left: 75px;
}

#modal-form .icon {
  position: absolute;
  bottom: 25%;
  color: var(--label-color);
  width: 40px;
  cursor: pointer;
}

#modal-form .icon.en {
  right: 20px;
  padding-left: 10px;
  border-left: 1px solid var(--label-color);
}

#modal-form .icon.ar {
  left: 20px;
  padding-right: 10px;
  border-right: 1px solid var(--label-color);
}

#modal-form .no-cursor {
  cursor: unset;
}


#modal-form .toggle-section label{
    font-weight: 700;
}

#modal-form .toggle-section .row{
    justify-content: center;
}

#modal-form .toggle-section .box{
    background-color: var(--light-gray);
    border: 1px solid var(--inactive-white-color);
    max-width: 208px;
    padding: 20px;
    border-radius: 10px;
}

#modal-form .toggle-section .element{
    display: flex;
    margin-bottom: 10px;
}

#modal-form .toggle-section span{
    color: var(--toggle-label-color);
    font-size: .875rem;
    flex: auto;
}

#modal-form .toggle{
background-color: var(--inactive-white-color);
width: 30px;
height: 15px;
border-radius: 20px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
}

#modal-form .toggle::before{
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--white-color);
    border: 2.5px solid var(--label-color);
    top: 0%;
    left: 0%;
}

#modal-form .toggle:hover{
cursor: pointer;
}

#modal-form .toggle.active{
background-color: var(--main-color);
}

#modal-form .toggle.active::before{
border-color: var(--main-color);
left: 50%;
}