.membership-img-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 5px;
}

.membership-img {
    width: 254px;
    height: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--table-header-color);
    border-radius: 50%;
    overflow: hidden;
}

.membership-img-container p {
    padding: 0;
    margin:4px 0;
}

.membership-name {
    text-align: center;
    font: normal normal bold 24px/28px Public Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.membership-status {
    text-align: center;
    font: normal normal normal 14px/30px Public Sans;
    letter-spacing: 0px;
    color: #36AACC;
    opacity: 1;
}

.membership-unique-id {
    text-align: right;
    font: normal normal normal 16px/19px Public Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.membership-info{
    margin-inline-end: auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--white-color);
    flex-direction: column;
}

.membership-info .membership-info-section{
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.membership-info .membership-info-section:not(:last-child){
    border-bottom: 1px solid var(--border-color);
}

.membership-info .membership-info-section .membership-info-title{
    font-weight: bold;
    text-transform: uppercase;
}

.membership-info .membership-info-section .membership-info-data{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.membership-info .membership-info-section .membership-info-data .info-badge{
    text-align: center;
    padding: 10px;
    background: var(--table-header-color);
    border: 1px solid var(--inactive-white-color);
    border-radius: 10px;
}

.membership-add-extra-btn {
    font-size: 14;
    color: rgba(224, 63, 63, 1);
    text-align: left;
}