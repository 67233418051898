#success-alert .contain ,#danger-alert .contain{
    max-width: 452px;
}

#success-alert .header ,#danger-alert .header{
    background-color: #218838 !important;
    text-align: center;
    padding: 14px 0;
}

#danger-alert .header{
    background-color: #ed2222 !important;
}

#success-alert .header .heading ,#danger-alert .header .heading{
    font-size: 1.4rem;
}

#success-alert .section ,#danger-alert .section{
    margin-bottom: 45px;
}

#success-alert .alert-message ,#danger-alert .alert-message{
    color: var(--primary-black-color);
    text-align: center;
    font-size: 1rem;
    padding: 0 5px;
    font-weight: 500;
    line-height: 25px;
}

#success-alert .confirm ,#danger-alert .confirm{
    display: flex;
}

#success-alert .btn ,#danger-alert .btn{
    padding: 10px 15px;
    flex: 50%;
    max-width: 50%;
}

#success-alert .btn-custom {
    background-color: #278838;
}