.chart-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px
}

.chart-child {
    height: auto;
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000014;
    border-radius: 3px;
    opacity: 1;
    padding: 20px;
    padding-top: 27px;
}

.expenses-stats-filters {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}

.expenses-stats-filters button {
    width: 114px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.expenses-stats-filters button:nth-child(3) {
    border-radius: 0px 24px 24px 0px;
}

.expenses-stats-filters button:nth-child(1) {
    border-radius: 24px 0px 0px 24px;
}

.expenses-stats-filters button.active {
    background: #191919 0% 0% no-repeat padding-box;
    color: white;
}

.expenses-stats-counters {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.expenses-stats-counters div {
    width: 100%;
    height: 140px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000014;
    border-top-width: 4px;
    border-top-style: solid;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.expenses-stats-counters div span.bold {
    font: normal normal bold 32px/37px Public Sans;
    letter-spacing: 0px;
    color: #191919;
}

@media (max-width: 1060px) {
    .chart-child {
        width: 50%;
    }

    .expenses-stats-counters {
        flex-wrap: wrap;
    }

    .expenses-stats-counters div {
        width: 30% !important;
    }
}

@media (max-width: 768px) {
    .chart-parent {
        display: flex;
        flex-direction: column;
    }
    .chart-child {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 900px) {
    .expenses-stats-counters div {
        width: 49% !important;
    }
}

@media (max-width: 600px) {
    .expenses-stats-counters div {
        width: 100% !important;
    }
}
