.item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    line-height: 30px;
    align-items: center;
}

.item-row-headline {
    font-weight: bold;
    color: #191919;
    font-size: 15px;
    margin: 0 !important;
    padding: 0 !important;
}

.working-card {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;
}

.working-card .action-div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.working-card .info-div {
    display: flex;
    flex-direction: column;
}

.working-card .time {
    color: #807F7F
}