#small-modal-form .contain
{
    max-width: 396px;
}

#small-modal-form .rounded-box
{
    width: 100%;
    min-width: 152px;
    max-width: 656px;
}

#small-modal-form .confirm
{
    display: flex;
    justify-content: flex-end;
}

#small-modal-form .form-group
{
    position: relative;
}

#small-modal-form .padding.en
{
    padding-right: 75px;
}

#small-modal-form .padding.ar
{
    padding-left: 75px;
}

#small-modal-form .ico{
    position: absolute;
    bottom: 25%;
    color: var(--label-color);
    width: 40px;
    cursor: pointer;
}

#small-modal-form .icon.en
{
    right: 20px;
    padding-left: 10px;
    border-left: 1px solid var(--label-color);
}

#small-modal-form .icon.ar
{
    left: 20px;
    padding-right: 10px;
    border-right: 1px solid var(--label-color);
}

#small-modal-form .no-cursor
{
    cursor: unset;
}

#small-modal-form .form-group
{
    padding-bottom: 0;
}